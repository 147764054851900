import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import Cookie from "js-cookie";
import ReactLoading from "react-loading";
const axios = require("axios").default;
//const URL = require("../../ApiUrl");

export default class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }
  logout() {
    //console.log(this.state);
    Cookie.remove("userData");
    Cookie.remove("jwtToken");
    this.setState({
      auth: false
    });
    window.location.reload(false);
  }

  componentDidMount() {
    this.logout();
  }

  render() {
    return (
      <>
        <ReactLoading color={"#faa"} />
        <Redirect to="login" />;
      </>
    );
  }
}
